export var SearchList = [
  { labe: '物料编号', code: 'itemNumber', type: 'input' },
  { labe: '物料描述', code: 'materialDescription', type: 'input' },
  { labe: '供应商', code: 'supplierName', type: 'input', placeholder: '编码/名称' },
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  {
    labe: '需求日期',
    code: 'demandDate',
    type: 'daterange'
  },
  {
    labe: '实际交付日期',
    code: 'actualDeliveryDate',
    type: 'daterange'
  },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' },
  {
    labe: '是否14天',
    code: 'is14Day',
    type: 'select',
    option: [
      { label: '是', value: '1' },
      { label: '否', value: '2' }
    ]
  }
]
export var SearchData = {
  itemNumber: '',
  materialDescription: '',
  supplierName: '',
  demandDate: '',
  actualDeliveryDate: '',
  purchaseOrderNumber: '',
  is14Day: ''
}

export var tableField = [
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '130' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '100' },
  { label: '分批行', code: 'batchLine', type: 'input', width: '' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '130' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '130' },
  { label: '订单数量', code: 'orderQuantity', type: 'input', width: '' },
  { label: '已交数量', code: 'deliveredQuantity', type: 'input', width: '' },
  { label: '未交数量', code: 'outstandingQuantity', type: 'input', width: '' },
  { label: '交付数量', code: 'deliveryQuantity', type: 'input', width: '' },
  { label: '创建时间', code: 'createDate', type: 'input', width: '150' },
  { label: '需求日期', code: 'demandDate', type: 'input', width: '150' },
  { label: 'LT日期', code: 'LTDate', type: 'input', width: '150' },
  { label: '供应商名称', code: 'supplierName', type: 'input', width: '130' },
  { label: 'srm修改时间', code: 'srmLastUpdateTime', type: 'input', width: '160' },
  { label: '原交付日期', code: 'actualDeliveryDate', type: 'input', width: '100' },
  { label: '实际交付日期', code: 'newDeliveryDate', type: 'input', width: '120' },
  { label: '修改人', code: 'updateUsername', type: 'input', width: '110' },
  { label: '修改原因', code: 'updateDeliveryTimeReason', type: 'input', width: '130' },
  {
    label: '是否14天',
    code: 'is14Day',
    type: 'function',
    width: '90px',
    handle: (index, data) => {
      return data.is14Day === '1' ? '是' : data.is14Day === '2' ? '否' : ''
    }
  }
]

export var tableData = []
export var menudata = [{
  label: '导出',
  action: 'Export',
  id: 1
}]
